<template>

</template>

<script>
import _ from 'lodash';

export default {
	name: 'tb-redirect',

	components: {},

	props : {

	},

	data: () => ({

	}),

	created() {
		// console.log(this.$route.path)
		// console.log(this.$route)
		if(_.includes(['test_domitys','liz-demo-fs','CF18q2Q5Dx','dA3RpDgfXX','Wu89cXjx71','EMTb4b3FTG','enxRedRaSR','n3VqTKKSvf','ZdKGCB249Q','uuESnHmmuM','4dcHenQuS8','cSEgYnbS9W','j27psDBSRH','Qj2eKaWDyV','GtupCEmScP','A218vtdVEm','46afw1uZFF','6etzAV9tbP','9A7n8Zcczf','dWV6BZFKkK','jbmXn4JWqc','bYTygd27AE'], this.$route.params.slug)){
            this.$router.push({name: 'tb-1-home', params: { slug: this.$route.params.slug }})
        }
	},

	mounted() {

	},

	computed: {

	},

	watch: {},

	methods: {}
};
</script>
